import { createContext, useContext } from 'react';
import { PageAnimationTypes } from '@zola/zola-ui/src/animations/PageAnimations/types';
import { FoilAnimationColors } from '@zola/zola-ui/src/animations/TextFoil/TextFoil';
import type {
  WSkuPreview,
  OverlayEffectStyleCustomizationRequest,
  WWebsiteEffectView,
} from '@zola/svc-web-api-ts-client';

export type EasingFunction = 'ease-in' | 'linear' | 'ease-out' | 'ease-in-out';

type AnimationContextType = {
  // should we show any info related to animation on edit website, ie is the user in the experiment
  showAnimationsInEditWebsite: boolean;
  // does the user's account have animations enabled (either they are in the free group or they paid for animations)
  animationsEnabledForAccount: boolean;
  enableAnimationsAfterPurchase: () => void;
  appliedAnimations: WWebsiteEffectView[];
  availableAnimations: WWebsiteEffectView[];
  setAppliedAnimations?: (animations: WWebsiteEffectView[]) => void;
  foilAnimation?: FoilAnimationColors;
  experimentVariation?: string;
  animationProduct: Pick<WSkuPreview, 'price_cents' | 'id'>;
  pageAnimation?: PageAnimationTypes;
  overlayAnimation?: WWebsiteEffectView;
  hideHeroTitleText?: boolean;
  forcePageAnimationUpdate?: () => void;
  pageAnimationId?: number;
  overlayAnimationId?: number;
  isMobileDevice?: boolean;
  updateAppliedAnimations: (animation: WWebsiteEffectView, isSelected: boolean) => void;
  updateOverlayAnimationCustomizations: (
    overlayCustomization: OverlayEffectStyleCustomizationRequest
  ) => Promise<void | null>;
  isEditWebsite?: boolean;
  showFoilAnimation?: boolean;
};

export const defaultAnimationContextValues: AnimationContextType = {
  showAnimationsInEditWebsite: false,
  animationsEnabledForAccount: false,
  enableAnimationsAfterPurchase: () => null,
  appliedAnimations: [],
  availableAnimations: [],
  setAppliedAnimations: () => null,
  experimentVariation: undefined,
  animationProduct: {} as WSkuPreview,
  foilAnimation: undefined,
  pageAnimation: undefined,
  showFoilAnimation: false,
  updateAppliedAnimations: () => null,
  updateOverlayAnimationCustomizations: () => Promise.resolve(),
  isMobileDevice: undefined,
  forcePageAnimationUpdate: () => null,
  pageAnimationId: undefined,
  overlayAnimationId: undefined,
  hideHeroTitleText: undefined,
  overlayAnimation: undefined,
  isEditWebsite: false,
};

export const AnimationContext = createContext<AnimationContextType>(defaultAnimationContextValues);

export const useAnimationContext = () => {
  const context = useContext(AnimationContext);
  if (context === undefined) {
    throw new Error('useAnimationContext must be used within an AnimationProvider');
  }
  return context;
};
