import { createContext, useContext } from 'react';

export type EasingFunction = 'ease-in' | 'linear' | 'ease-out' | 'ease-in-out';

type AnimationDebugContextType = {
  showDebug?: boolean;
  setShowDebug?: (debug: boolean) => void;
  speed?: number;
  speedHero?: number;
  speedNav?: number;
  setSpeed?: (speed: number) => void;
  setSpeedHero?: (speed: number) => void;
  setSpeedNav?: (speed: number) => void;
  startScaleAt?: number;
  startScaleAtHero?: number;
  startScaleAtNav?: number;
  setStartScaleAt?: (scale: number) => void;
  setStartScaleAtHero?: (scale: number) => void;
  setStartScaleAtNav?: (scale: number) => void;
  translateYPercent?: number;
  translateYPercentHero?: number;
  translateYPercentNav?: number;
  setTranslateYPercent?: (percent: number) => void;
  setTranslateYPercentNav?: (percent: number) => void;
  setTranslateYPercentHero?: (percent: number) => void;
  easingFunction?: EasingFunction;
  easingFunctionHero?: EasingFunction;
  easingFunctionNav?: EasingFunction;
  setEasingFunction?: (func: EasingFunction) => void;
  setEasingFunctionHero?: (func: EasingFunction) => void;
  setEasingFunctionNav?: (func: EasingFunction) => void;
  translateXPercent?: number;
  translateXPercentHero?: number;
  translateXPercentNav?: number;

  setTranslateXPercent?: (percent: number) => void;
  setTranslateXPercentHero?: (percent: number) => void;
  setTranslateXPercentNav?: (percent: number) => void;
};

export const defaultAnimationDebugContextValues: AnimationDebugContextType = {
  showDebug: false,
  setShowDebug: () => null,

  translateYPercent: 0,
  translateYPercentHero: 0,
  translateYPercentNav: 0,
  setTranslateYPercent: () => null,
  setTranslateYPercentNav: () => null,
  setTranslateYPercentHero: () => null,
  easingFunction: undefined,
  setEasingFunction: () => null,

  translateXPercent: undefined,
  setTranslateXPercent: () => null,
  translateXPercentHero: undefined,
  translateXPercentNav: undefined,

  setTranslateXPercentHero: () => null,
  setTranslateXPercentNav: () => null,

  startScaleAtHero: undefined,
  startScaleAtNav: undefined,
  setStartScaleAtHero: () => null,
  setStartScaleAtNav: () => null,
  speedHero: undefined,
  speedNav: undefined,
  setSpeedHero: () => null,
  setSpeedNav: () => null,
};

export const AnimationDebugContext = createContext<AnimationDebugContextType>(
  defaultAnimationDebugContextValues
);

export const useAnimationDebugContext = () => {
  const context = useContext(AnimationDebugContext);
  if (context === undefined) {
    throw new Error('useAnimationDebugContext must be used within an AnimationProvider');
  }
  return context;
};
